import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import ReturnArrow from '../../../../components/buttons/ReturnArrow';
import useStyle from '../../../../hooks/useStyle';
import { CreateHidRecord, GetAllStatusForDevice } from '../../../../reduxToolkit/HID/HidApi';

const CreateController = () => {
    const dispatch = useDispatch();
    const allStatus = useSelector((state) => state.HidSlice.getAllStatusForDevice);
    const lCode = Cookies.get('i18next') || 'en';
    const navigate = useNavigate();
    const { textField, smallBoxStyle, textFieldCommon } = useStyle();

    const [name, setName] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [statusID, setStatusID] = useState('');
    const [scpId, setScpId] = useState('');
    const [channelId, setChannelId] = useState('');
    const [description, setDescription] = useState('');
    const handleScpIdChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value === '' || parseInt(value) < 1) {
            value = '1';
        }
        setScpId(value);
    };
    const handleSubmit = () => {
        const body = {
            channelId: parseInt(channelId),
            hidName: name,
            ipAddress: ipAddress,
            statusId: parseInt(statusID),
            scpId: parseInt(scpId),
            name: name,
            description: description,
        };

        dispatch(CreateHidRecord(body)).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success === true) {
                    toast.success('Controller_created_successfully').toUpperCase();
                    navigate('/dashboard/employee/controller');
                } else {
                    toast.error(t('Error_creating_controller').toUpperCase());
                }
            },
        );
    };

    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow id='btn_cc_back' link={'/dashboard/employee/controller'} />
                    <h2>{t('create')}</h2>
                </div>
            </div>
            <div className='mt-5 row order_data_component'>
                <div className='formCard'>
                    <div className='col-md-11'>
                        <p className='__header'>{t('data')}</p>
                        <div className='__body'>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <TextField
                                        id='txt_cc_name'
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        required
                                        label={t('name')}
                                        inputProps={{ maxLength: 128 }}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <TextField
                                        id='txt_cc_ip'
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        required
                                        label={t('ip')}
                                        inputProps={{ maxLength: 128 }}
                                        value={ipAddress}
                                        onChange={(e) => setIpAddress(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <Grid item xs={12} style={{ width: '100%' }}>
                                        <Box className='inputField required-data'>
                                            <FormControl fullWidth required>
                                                <InputLabel id='status-label'>{t('status')}</InputLabel>
                                                <Select
                                                    id='slt_cc_status'
                                                    size='small'
                                                    labelId='status-label'
                                                    value={statusID}
                                                    onChange={(e) => setStatusID(e.target.value)}
                                                >
                                                    {allStatus.map((status) => (
                                                        <MenuItem key={status.id} value={status.id}>
                                                            {t(status.name)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </div>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        id='txt_cc_number'
                                        className='inputField'
                                        fullWidth
                                        required
                                        label={t('SCP_ID')}
                                        type='number'
                                        inputProps={{ maxLength: 128, min: 1 }}
                                        value={scpId}
                                        onChange={handleScpIdChange}
                                    />
                                </div>
                            </div>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        className='inputField'
                                        id='txt_cc_channelId'
                                        fullWidth
                                        required
                                        label={t('CHANNEL_ID')}
                                        inputProps={{ maxLength: 128 }}
                                        value={channelId}
                                        onChange={(e) => setChannelId(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <div className='checkbox-common col-md-6'></div>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    id='txt_cc_description'
                                    label={t('description')}
                                    value={description}
                                    inputProps={{ maxLength: 512 }}
                                    onChange={(e) => setDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                    style={{ color: '#707070' }}
                                />
                            </div>

                            <div className='footer'>
                                <button id='btn_cc_cancel' className='cancel'>
                                    {t('cancel').toUpperCase()}
                                </button>
                                <button id='btn_cc_create' onClick={() => handleSubmit()}>
                                    {t('create').toUpperCase()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateController;
