import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    Divider,
    Grid,
    FormHelperText,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    IconButton,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BadgeIcon from '@mui/icons-material/Badge';
import { DatePicker, DateRangePicker, LocalizationProvider, DesktopDatePicker, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import ClearButton from '../../../../../components/ClearButton';
import { status } from '../../../../../enums/statusEnum';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import dateInputLanguage from '../../../../../hooks/dateInputLanguage';
import phoneInputLanguage from '../../../../../hooks/phoneInputLanguage';
import DeleteIcon from '@mui/icons-material/Delete';
import Step2OtherDetails from './Step2OtherDetails';
import { GetHeaders } from '../../../../../reduxToolkit/headers/HeadersApi';

const Step1Details = ({
    userData,
    onChange,
    setUserData,
    emptyFields,
    isValidEmail,
    setIsValidEmail,
    setIsValidPhoneNumber,
    isValidPhoneNumber,
    isValidDob,
    setIsValidDob,
    language,
    profile,
    modalShow,
    setModalShow,
    isValidDocumentId,
    setIsValidDocumentId,
}) => {
    const { headersList } = useSelector((state) => state.headersSlice);
    const dispatch = useDispatch();

    const [contractorId, setContractorId] = useState(sessionStorage.getItem('contractorId'));
    const [headerObj, setHeaderObj] = useState({
        header1: '',
        header2: '',
        header3: '',
        header4: '',
        header5: '',
        header6: '',
        header7: '',
        header8: '',
        header9: '',
        header10: '',
        header11: '',
        header12: '',
        header13: '',
        header14: '',
        header15: '',
    });

    useEffect(() => {
        dispatch(GetHeaders())
            .unwrap()
            .then(({ data: { data } }) => {
                setHeaderObj({
                    header1: data?.header1 || 'header_1',
                    header2: data?.header2 || 'header_2',
                    header3: data?.header3 || 'header_3',
                    header5: data?.header4 || 'header_4',
                    header6: data?.header5 || 'header_5',
                    header7: data?.header6 || 'header_6',
                    header8: data?.header7 || 'header_7',
                    header9: data?.header8 || 'header_8',
                    header10: data?.header9 || 'header_9',
                    header11: data?.header10 || 'header_10',
                    header12: data?.header11 || 'header_11',
                    header13: data?.header12 || 'header_12',
                    header14: data?.header13 || 'header_13',
                    header15: data?.header14 || 'header_14',
                    header4: data?.header15 || 'header_15',
                });
            });
    }, []);

    const { extraData } = userData;
    const { t } = useTranslation();
    const lCode = Cookies.get('i18next') || 'en';
    const [showPassword, setShowPassword] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);

    const userInfoStatusList = useSelector((state) => state?.CompanyEmployeesSlice?.userInfoStatusList);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleDateChange = (selectedDate) => {
        setUserData((prevData) => ({
            ...prevData,
            dob: selectedDate,
        }));
    };

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(userData.email);
        setIsValidEmail(isValid);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\+[0-9]{10,14}$/;
        const isValid = phoneRegex.test('+' + phone);
        setIsValidPhoneNumber(isValid);
    };

    const handleEmailChange = (event) => {
        onChange(event); // Execute the original onChange function
    };

    const handlePhoneNumberChange = (phone) => {
        handlePhoneChange(phone);
        validatePhone(phone);
    };

    const handlePhoneChange = (phone) => {
        setUserData((prevData) => ({
            ...prevData,
            phoneNumber: phone,
        }));
    };

    const handleDocumentIdChange = (event) => {
        const value = event.target.value;
        setUserData((prevData) => ({ ...prevData, documentId: value }));
    };
    const validateDocumentId = (documentId) => {
        if (typeof documentId !== 'string') {
            return false;
        }

        const length = documentId.length;
        return (length >= 6 && length <= 10) || length === 18;
    };
    //This section indicates what to do when we click clean button
    const handleClear = () => {
        setUserData((prevData) => ({
            ...prevData,
            name: '',
            lastName: '',
            secondLastName: '',
            genderId: '',
            phoneNumber: '',
            email: '',
            dob: null,
            password: '',
        }));
    };

    const smallBoxStyle = {
        width: '100%',
        maxWidth: '100%',
        fontSize: '20px',
        height: '50px',
    };

    const textField = {
        textAlign: lCode === 'ar' ? 'right' : 'left',
        borderRadius: '7px',
        '& .MuiInputBase-input::placeholder': {
            color: 'black!important',
            // opacity: 1,
        },
        '& .MuiInputBase-root': {
            color: 'black!important',
        },
        '& label.Mui-focused': {
            color: 'black!important',
        },
        '& label': {
            color: 'black!important',
        },
        '&:hover label': {
            color: 'black!important',
        },
        '& 	.MuiOutlinedInput-notchedOutline': {
            textAlign: lCode === 'ar' ? 'right' : 'left',
        },
        '& 	.MuiInputLabel-root': {
            fontSize: 12,
            fontWeight: 'bold',
            marginTop: '2px',
            alignItems: 'center',
            display: 'flex',
            left: lCode === 'ar' ? 'inherit' : '0',
            right: lCode === 'ar' ? '1.75rem' : '0',
            transformOrigin: lCode === 'ar' ? 'right' : 'left',
            zIndex: 0,
        },
        '& 	.MuiFormLabel-filled': {
            marginTop: '-5px',
        },
    };

    return (
        <div>
            <div className='create-enrollment-data'>
                {(userData.id === null || userData.id === '') && profile !== true && (
                    <ClearButton
                        className='mt-0 pt-0'
                        handleClear={handleClear}
                        flagTooltip={true}
                        textTooltip={t('clean_all_fields').toUpperCase()}
                    />
                )}
                <div>
                    <div className='row'>
                        <div className='col-md-6 pt-3'>
                            <Box className='requiredData' sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    error={emptyFields && userData.name.trim() === ''}
                                    fullWidth
                                    required
                                    name='name'
                                    label={t('name')}
                                    id='txt_s1d_name'
                                    value={userData.name}
                                    onChange={onChange}
                                    sx={textField}
                                    inputProps={{ maxLength: 128 }}
                                    helperText={emptyFields && userData.name.trim() === '' ? t('requiredField') : ''}
                                    FormHelperTextProps={{
                                        sx: {
                                            color: 'red!important',
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#707070!important',
                                            border: 'black 1px!important',
                                        },
                                    }}
                                />
                            </Box>
                        </div>
                        <div className='col-md-6 pt-3'>
                            <Box className='requiredData' sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    error={emptyFields && userData.lastName.trim() === ''}
                                    fullWidth
                                    required
                                    name='lastName'
                                    label={t('last_name')}
                                    id='txt_s1d_lastName'
                                    value={userData.lastName}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 128 }}
                                    sx={textField}
                                    helperText={
                                        emptyFields && userData.lastName.trim() === '' ? t('requiredField') : ''
                                    }
                                />
                            </Box>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 pt-3'>
                            <Box sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    name='secondLastName'
                                    inputProps={{ maxLength: 128 }}
                                    label={t('second_last_name')}
                                    id='txt_s1d_secondLastName'
                                    value={userData.secondLastName}
                                    onChange={onChange}
                                    sx={textField}
                                />
                            </Box>
                        </div>
                        <div className='col-md-6 pt-3'>
                            <Box className='requiredData' sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    fullWidth
                                    name='email'
                                    inputProps={{ maxLength: 128 }}
                                    label={t('email')}
                                    value={userData.email}
                                    onChange={handleEmailChange}
                                    sx={textField}
                                    id='txt_s1d_email'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 pt-3'>
                            <Box sx={smallBoxStyle}>
                                <FormControl fullWidth sx={textField}>
                                    <InputLabel size='small' id='demo-simple-select-label'>
                                        {t('gender')}
                                    </InputLabel>
                                    <Select
                                        size='small'
                                        labelId='demo-simple-select-label'
                                        id='slt_s1d_genderId'
                                        name='genderId'
                                        label={t('gender55')}
                                        value={userData.genderId}
                                        onChange={onChange}
                                    >
                                        <MenuItem value={1}>{t('male').toUpperCase()}</MenuItem>
                                        <MenuItem value={2}>{t('female').toUpperCase()}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </div>
                        <div className='col-md-6 pt-3'>
                            <Box className='requiredData' sx={smallBoxStyle}>
                                <FormControl
                                    fullWidth
                                    required
                                    error={
                                        (emptyFields &&
                                            (userData.phoneNumber.trim() === '' ||
                                                userData.phoneNumber.trim() === '+')) ||
                                        (emptyFields && !isValidPhoneNumber) ||
                                        (userData.id !== '' && !isValidPhoneNumber)
                                    }
                                    sx={textField}
                                >
                                    <PhoneInput
                                        inputStyle={{
                                            ...((emptyFields &&
                                                (userData.phoneNumber.trim() === '' ||
                                                    userData.phoneNumber.trim() === '+')) ||
                                            (userData.id !== '' &&
                                                (userData.phoneNumber.trim() === '' ||
                                                    userData.phoneNumber.trim() === '+'))
                                                ? {
                                                      //*borderColor: '#d32f2f',*//
                                                      color: '#000000',
                                                  }
                                                : ((emptyFields && !isValidPhoneNumber) ||
                                                      (userData.id !== '' && !isValidPhoneNumber)) && {
                                                      borderColor: '#d32f2f',
                                                      color: '#000000',
                                                  }),
                                            color: '#000000',
                                            backgroundColor: 'transparent',
                                        }}
                                        // labelStyle={{ backgroundColor: 'white' }}
                                        country={'mx'}
                                        enableSearch={true}
                                        placeholder=''
                                        name='phoneNumber'
                                        id='txt_s1d_phoneNumber'
                                        value={userData.phoneNumber}
                                        specialLabel={(t('employee_phone_number') + '*').toUpperCase()}
                                        onChange={handlePhoneNumberChange}
                                        localization={phoneInputLanguage(language)}
                                        inputProps={{
                                            name: 'phoneNumber',
                                            require: true,
                                        }}
                                    />
                                    {(emptyFields &&
                                        (userData.phoneNumber.trim() === '' || userData.phoneNumber.trim() === '+')) ||
                                    (userData.id !== '' &&
                                        (userData.phoneNumber.trim() === '' || userData.phoneNumber.trim() === '+')) ? (
                                        <FormHelperText className='select_helper_text'>
                                            {t('requiredField')}
                                        </FormHelperText>
                                    ) : (
                                        ((emptyFields && !isValidPhoneNumber) ||
                                            (userData.id !== '' && !isValidPhoneNumber)) && (
                                            <FormHelperText className='select_helper_text'>
                                                {t('phone_number_format_invalid')}
                                            </FormHelperText>
                                        )
                                    )}
                                </FormControl>
                            </Box>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 pt-3'>
                            <Box className='requiredData' sx={smallBoxStyle}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateInputLanguage(lCode)}>
                                    <DesktopDatePicker
                                        allowSameDateSelection
                                        name='dob'
                                        label={t('dob')}
                                        id='dtp_s1d_dob'
                                        inputFormat='dd/MM/yyyy'
                                        value={userData.dob}
                                        onChange={handleDateChange}
                                        onError={(error) => setIsValidDob(error === null)}
                                        renderInput={(params) => (
                                            <TextField
                                                name='dob'
                                                size='small'
                                                sx={textField}
                                                fullWidth
                                                helperText={!isValidDob && t('date_invalid')}
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </div>
                        <div className='col-md-6 pt-3'>
                            <Box className='requiredData' sx={smallBoxStyle}>
                                <TextField
                                    size='small'
                                    error={
                                        emptyFields &&
                                        (userData.documentId.trim().length < 5 || userData.documentId.trim() === '')
                                    }
                                    fullWidth
                                    required
                                    name='documentId'
                                    label={t('documentId')}
                                    id='txt_s1d_documentId'
                                    value={userData.documentId}
                                    onChange={handleDocumentIdChange}
                                    sx={textField}
                                    helperText={t('document_id_format')}
                                    FormHelperTextProps={{
                                        sx: {
                                            color: '#707070',
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <BadgeIcon />
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            maxLength: 25,
                                        },
                                    }}
                                />
                            </Box>
                        </div>
                    </div>
                    <div className='row'>
                        {userData.statusId !== '' && userData.statusId >= 4 ? (
                            <div className='col-md-6 pt-3'>
                                <Box sx={smallBoxStyle}>
                                    <FormControl fullWidth sx={textField}>
                                        <InputLabel size='small' id='demo-simple-select-label'>
                                            {t('status')}
                                        </InputLabel>
                                        <Select
                                            size='small'
                                            labelId='demo-simple-select-label'
                                            name='statusId'
                                            id='slt_s1d_status'
                                            label={t('status')}
                                            value={userData.statusId}
                                            onChange={onChange}
                                        >
                                            {userInfoStatusList?.map((item) => (
                                                <MenuItem key={item?.id} value={item?.id}>
                                                    {t(status[item?.id])}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        ) : profile === true && userData.deviceId !== null ? (
                            <div className='col-md-6 pt-3'>
                                <Box sx={smallBoxStyle}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        name='deviceId'
                                        disabled
                                        inputProps={{ maxLength: 128 }}
                                        label={t('device_id')}
                                        value={userData.deviceId}
                                        onChange={onChange}
                                        sx={textField}
                                        id='txt_s1d_deviceId'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='end'
                                                    onMouseEnter={() => setHovered(true)}
                                                    onMouseLeave={() => setHovered(false)}
                                                >
                                                    <DeleteIcon
                                                        aria-label='toggle password visibility'
                                                        onClick={() => setModalShow(true)}
                                                        edge='end'
                                                        style={{ cursor: hovered ? 'pointer' : 'default' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </div>
                        ) : (
                            <div className='col-md-6 pt-3'>
                                <Box sx={smallBoxStyle}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        hidden
                                        label={t('blank_space')}
                                        id='blank'
                                        sx={textField}
                                    />
                                </Box>
                            </div>
                        )}
                        {userData.statusId !== '' && userData.statusId >= 4 && (
                            <div className='col-md-6 pt-3'>
                                <Box sx={smallBoxStyle}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        name='password'
                                        type={showPassword ? 'text' : 'password'}
                                        inputProps={{ maxLength: 128 }}
                                        label={t('password')}
                                        value={userData.password}
                                        onChange={onChange}
                                        sx={{
                                            ...textField,
                                            '& .MuiFormHelperText-root': {
                                                color: '#707070b3!important',
                                            },
                                        }}
                                        id='txt_s1d_password'
                                        helperText={t('fill_only_if_you_want_to_update_your_password')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge='end'
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </div>
                        )}
                        {userData.statusId !== '' &&
                        userData.statusId >= 4 &&
                        profile === true &&
                        userData.deviceId !== null ? (
                            <div className='col-md-6 pt-3'>
                                <Box sx={smallBoxStyle}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        name='deviceId'
                                        disabled
                                        inputProps={{ maxLength: 128 }}
                                        label={t('device_id')}
                                        value={userData.deviceId}
                                        onChange={onChange}
                                        sx={textField}
                                        id='txt_s1d_deviceId'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position='end'
                                                    onMouseEnter={() => setHovered(true)}
                                                    onMouseLeave={() => setHovered(false)}
                                                >
                                                    <DeleteIcon
                                                        aria-label='toggle password visibility'
                                                        onClick={() => setModalShow(true)}
                                                        edge='end'
                                                        style={{ cursor: hovered ? 'pointer' : 'default' }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </div>
                        ) : (
                            <div className='col-md-6 pt-3'>
                                <Box sx={smallBoxStyle}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        hidden
                                        label={t('blank_space')}
                                        id='blank'
                                        sx={textField}
                                    />
                                </Box>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step1Details;
