import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Tooltip,
    Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import ReturnArrow from '../../../../components/buttons/ReturnArrow';
import useStyle from '../../../../hooks/useStyle';
import AddButtonPlus from '../../../../components/AddButtonPlus';
import connect from '../../../../assets/icon/ic-link.svg';
import config from '../../../../assets/icon/ic-setting.svg';
import reset from '../../../../assets/icon/ban-solid.svg';
import NotInformation from '../../../../components/NotInformation';
import arrowUP from '../../../../assets/Controller/arrow-up-solid.png';
import arrowDown from '../../../../assets/Controller/arrow-down-solid.png';
import check from '../../../../assets/Controller/ic-check.png';
import cancel from '../../../../assets/Controller/ic-cancel.png';
import CreateInputs from '../Inputs/CreateInputs';
import CreateSIOSModal from '../SIOs/CreateSIOSModal';
import CreateReaderModal from '../Readers/CreateReaderModal';
import {
    ConnectWithHid,
    DeleteRecordsByTableAndIds,
    GetAllSiosByHidId,
    GetHidRecordDetails,
    ResetHid,
    SendInformationSetting,
    UpdateHidRecord,
} from '../../../../reduxToolkit/HID/HidApi';
import CreateSIOSForController from '../SIOs/CreateSIOSForController';
import CreateReaderForController from '../Readers/CreateReaderForController';
import CreateInputsForController from '../Inputs/CreateInputsForController';
import DeleteModal from '../../../Modals/DeleteModal';
import CreateFormats from '../Formats/CreateFormats';
import { GetAllCardFormats } from '../../../../reduxToolkit/Cards/CardsApi';
import { id } from 'date-fns/locale';
const ControllerDetails = () => {
    const lCode = Cookies.get('i18next') || 'en';
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { searchByFilters } = useSelector((state) => state.SearchSlice);
    const [isAllCheckedInput, setIsAllCheckedInput] = useState(false);
    const [isAllCheckedSio, setIsAllCheckedSio] = useState(false);
    const [isAllCheckedFormat, setIsAllCheckedFormat] = useState(false);
    const [isAllCheckedReader, setIsAllCheckedReader] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { id: paramId } = useParams();
    const [id, setId] = useState(paramId);
    const { getHidRecordDetails } = useSelector((state) => state.HidSlice);
    const [originalHidDetails, setOriginalHidDetails] = useState({});
    const [showCreateSIOSModal, setShowCreateSIOSModal] = useState(false);
    const [showCreateFormatModal, setShowCreateFormatModal] = useState(false);
    const [showCreateReaderModal, setShowCreateReaderModal] = useState(false);
    const [showCreateInputModal, setShowCreateInputModal] = useState(false);
    const [hidDetails, setHidDetails] = useState({ description: '' });
    const [controllerId, setControllerId] = useState('');
    const [changed, setChanged] = useState(false);
    const [description, setDescription] = useState(getHidRecordDetails?.description || '');
    const [scpId, setScpId] = useState(getHidRecordDetails?.scpId || '');
    const [channelId, setChannelId] = useState(getHidRecordDetails?.channelId || '');
    const [ipAddress, setIpAddress] = useState(getHidRecordDetails?.ipAddress || '');
    const [name, setName] = useState(getHidRecordDetails?.name || '');
    const [status, setStatus] = useState(getHidRecordDetails.statusId);

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };
    const handleScpIdChange = (event) => {
        setScpId(event.target.value);
    };
    const handleChannelIdChange = (event) => {
        setChannelId(event.target.value);
    };
    const handleIpAddressChange = (event) => {
        setIpAddress(event.target.value);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    useEffect(() => {
        setId(paramId);
    }, [paramId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    await dispatch(GetHidRecordDetails({ id: id }));
                } else {
                    console.error('ID is undefined');
                }
            } catch (error) {
                console.error('Error fetching HID record details:', error);
            }
        };

        fetchData();
    }, [dispatch, id]);

    useEffect(() => {
        if (getHidRecordDetails) {
            setScpId(getHidRecordDetails?.scpId ?? '');
            setChannelId(getHidRecordDetails?.channelId ?? '');
            setDescription(getHidRecordDetails?.description ?? '');
            setIpAddress(getHidRecordDetails?.ipAddress ?? '');
            setName(getHidRecordDetails?.name ?? '');
            setStatus(getHidRecordDetails.statusId ?? '');
        }
    }, [getHidRecordDetails]);

    useEffect(() => {
        if (getHidRecordDetails) {
            setOriginalHidDetails(getHidRecordDetails);
        }
    }, []);

    const [deleteInputShow, setDeleteInputShow] = useState(false);
    const [selectInputForDelete, setSelectInputForDelete] = useState([]);
    const [deleteFormatShow, setDeleteFormatShow] = useState(false);
    const [selectFormatForDelete, setSelectFormatForDelete] = useState([]);
    const [deleteSioShow, setDeleteSioShow] = useState(false);
    const [selectSioForDelete, setSelectSioForDelete] = useState([]);
    const [deleteReaderShow, setDeleteReaderShow] = useState(false);
    const [selectReaderForDelete, setSelectReaderForDelete] = useState([]);
    const handleAddButtonClick = () => {
        dispatch(GetAllCardFormats());
        setShowCreateFormatModal(true);
    };
    const deleteSelectedInput = (deleteItem) => {
        const body = deleteItem;
        const table = 'hid_input';

        if (body.length > 0) {
            dispatch(DeleteRecordsByTableAndIds({ table, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectInputForDelete([]);
                        setIsAllCheckedInput(false);
                        dispatch(GetHidRecordDetails({ id: controllerId }));
                    } else {
                    }
                },
            );
        }
    };
    const deleteSelectedReader = (deleteItem) => {
        const body = deleteItem;
        const table = 'hid_reader';

        if (body.length > 0) {
            dispatch(DeleteRecordsByTableAndIds({ table, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectReaderForDelete([]);
                        setIsAllCheckedReader(false);
                        dispatch(GetHidRecordDetails({ id: controllerId }));
                        dispatch(GetAllSiosByHidId({ hidId: controllerId }));
                    } else {
                    }
                },
            );
        }
    };
    const deleteSelectedSio = (deleteItem) => {
        const body = deleteItem;
        const table = 'hid';

        if (body.length > 0) {
            dispatch(DeleteRecordsByTableAndIds({ table, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectSioForDelete([]);
                        setIsAllCheckedSio(false);
                        dispatch(GetHidRecordDetails({ id: controllerId }));
                    } else {
                    }
                },
            );
        }
    };
    const deleteSelectedFormat = (deleteItem) => {
        const body = deleteItem;
        const table = 'hid_card_format';

        if (body.length > 0) {
            dispatch(DeleteRecordsByTableAndIds({ table, body })).then(
                ({
                    payload: {
                        data: { success },
                    },
                }) => {
                    if (success === true) {
                        setLoaded(true);
                        setSelectFormatForDelete([]);
                        setIsAllCheckedFormat(false);
                        dispatch(GetHidRecordDetails({ id: controllerId }));
                    } else {
                    }
                },
            );
        }
    };
    const handleCheckboxChangeInput = (e) => {
        const id = e.target.id;
        if (e.target.checked) {
            setSelectInputForDelete((prevState) => [...prevState, id]);
        } else {
            setSelectInputForDelete((prevState) => prevState.filter((removeId) => removeId !== id));
        }
    };
    const handleCheckboxChangeReader = (e) => {
        const id = e.target.id;
        if (e.target.checked) {
            setSelectReaderForDelete((prevState) => [...prevState, id]);
        } else {
            setSelectReaderForDelete((prevState) => prevState.filter((removeId) => removeId !== id));
        }
    };
    const handleCheckboxChangeSio = (e) => {
        const id = e.target.id;
        if (e.target.checked) {
            setSelectSioForDelete((prevState) => [...prevState, id]);
        } else {
            setSelectSioForDelete((prevState) => prevState.filter((removeId) => removeId !== id));
        }
    };
    const handleCheckboxChangeFormat = (e) => {
        const id = e.target.id;
        if (e.target.checked) {
            setSelectFormatForDelete((prevState) => [...prevState, id]);
        } else {
            setSelectFormatForDelete((prevState) => prevState.filter((removeId) => removeId !== id));
        }
    };

    const handelDeleteAllInputs = (e) => {
        setIsAllCheckedInput(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getHidRecordDetails?.sios?.flatMap((sio) => sio.inputs?.map((input) => input?.id));
            setSelectInputForDelete(selectAllIds);
        } else {
            setSelectInputForDelete([]);
        }
    };
    const handelDeleteAllReader = (e) => {
        setIsAllCheckedReader(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getHidRecordDetails?.sios?.flatMap((sio) => sio.readers?.map((item) => item?.id));
            setSelectReaderForDelete(selectAllIds);
        } else {
            setSelectReaderForDelete([]);
        }
    };
    const handelDeleteAllSio = (e) => {
        setIsAllCheckedSio(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getHidRecordDetails?.sios?.map((item) => item?.id);
            setSelectSioForDelete(selectAllIds);
        } else {
            setSelectSioForDelete([]);
        }
    };
    const handelDeleteAllFormat = (e) => {
        setIsAllCheckedFormat(e.target.checked);
        if (e.target.checked) {
            const selectAllIds = getHidRecordDetails?.formats?.map((item) => {
                return item?.id;
            });
            setSelectFormatForDelete(selectAllIds);
        } else {
            setSelectFormatForDelete([]);
        }
    };
    useEffect(() => {
        if (isAllCheckedInput) {
            const selectAllIds = getHidRecordDetails?.sios?.flatMap((sio) => sio.inputs?.map((input) => input?.id));
            setSelectInputForDelete(selectAllIds);
        }
    }, [getHidRecordDetails, isAllCheckedInput]);

    useEffect(() => {
        if (isAllCheckedSio) {
            const selectAllIds = getHidRecordDetails?.sios?.map((item) => item?.id);
            setSelectSioForDelete(selectAllIds);
        }
    }, [getHidRecordDetails, isAllCheckedSio]);
    useEffect(() => {
        if (isAllCheckedReader) {
            const selectAllIds = getHidRecordDetails?.sios?.flatMap((sio) => sio.readers?.map((item) => item?.id));
            setSelectReaderForDelete(selectAllIds);
        }
    }, [getHidRecordDetails, isAllCheckedReader]);

    useEffect(() => {
        if (isAllCheckedFormat) {
            const selectAllIds = getHidRecordDetails?.formats?.map((item) => item?.id);
            setSelectFormatForDelete(selectAllIds);
        }
    }, [getHidRecordDetails, isAllCheckedFormat]);

    useEffect(() => {
        if (getHidRecordDetails) {
            setHidDetails(getHidRecordDetails);
            setControllerId(getHidRecordDetails.id);
        }
    }, [getHidRecordDetails]);
    const deleteSio = (deleteSio) => {
        dispatch({ type: 'hid/deleteSio', payload: deleteSio });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };
    const deleteFormat = (deleteFormat) => {
        dispatch({ type: 'hid/deleteFormat', payload: deleteFormat });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };
    const deleteInput = (deleteInput) => {
        dispatch({ type: 'hid/deleteInput', payload: deleteInput });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };
    const deleteReader = (deleteReader) => {
        dispatch({ type: 'hid/deleteReader', payload: deleteReader });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };
    const addNewSio = (newSio) => {
        dispatch({ type: 'hid/addNewSio', payload: newSio });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };
    const addNewFormat = (newFormat) => {
        dispatch({ type: 'hid/addNewFormat', payload: newFormat });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };
    const addNewReader = (newReader) => {
        dispatch(GetAllSiosByHidId({ hidId: controllerId }));
        dispatch({ type: 'hid/addNewReader', payload: newReader });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };
    const addNewInput = (newInput) => {
        dispatch({ type: 'hid/addNewInput', payload: newInput });
        dispatch(GetHidRecordDetails({ id: controllerId }));
    };

    const spanStyles = {
        backgroundColor: '#146f62',
        height: '3px',
        width: '100%',
    };

    const handleConnect = () => {
        const id = getHidRecordDetails.id;
        dispatch(ConnectWithHid({ id }));
    };

    const handleSetConfig = () => {
        const id = getHidRecordDetails.id;
        dispatch(SendInformationSetting({ id }));
    };

    const handleReset = () => {
        const id = getHidRecordDetails.id;
        dispatch(ResetHid({ id }));
    };
    const handleSubmit = () => {
        const body = {
            channelId: channelId,
            description: description,
            formats: getHidRecordDetails.formats,
            id: controllerId,
            ipAddress: ipAddress,
            name: name,
            scpId: scpId,
            sios: getHidRecordDetails.sios,
            statusId: status,
        };

        dispatch(UpdateHidRecord(body)).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success === true) {
                    toast.success(t('Controller_updated_successfully').toUpperCase());

                    navigate('/dashboard/employee/controller');
                } else {
                    toast.error(t('Error_updating_controller').toUpperCase());
                }
            },
        );
    };

    const handleSubmitCancel = () => {
        const body = originalHidDetails;

        dispatch(UpdateHidRecord(body)).then(
            ({
                payload: {
                    data: { success },
                },
            }) => {
                if (success === true) {
                    toast.success(t('Controller_reset_to_original_successfully').toUpperCase());

                    navigate('/dashboard/employee/controller');
                } else {
                    toast.error(t('Error_resetting_controller').toUpperCase());
                }
            },
        );
    };
    return (
        <>
            <div className='head'>
                <div className='headLeft'>
                    <ReturnArrow onClick={handleSubmitCancel} link={'/dashboard/employee/controller'} />
                    <h2>{t('details')}</h2>
                </div>
                <div className='container-top-right-btns'>
                    <button id='btn_cd_connect' className='add-btn-1' onClick={handleConnect}>
                        <img src={connect} alt='' />
                        {t('connect')}
                    </button>
                    <button id='btn_cd_setConfig' className='add-btn-2' onClick={handleSetConfig}>
                        <img src={config} alt='' />
                        {t('set_config')}
                    </button>
                    <button id='btn_cd_reset' className='add-btn-3' onClick={handleReset}>
                        <img src={reset} alt='' className='reset-icon' />
                        {t('Reset')}
                    </button>
                </div>
            </div>
            <div className='mt-5 row order_data_component'>
                <div className='formCard'>
                    <div className='col-md-11'>
                        <Grid display='flex' gap='0.8rem' alignItems='baseline'>
                            <p className='__header'>{t('data')}</p>
                            <div className='span' style={spanStyles}></div>
                        </Grid>
                        <div className='__body1'>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        label={t('id')}
                                        inputProps={{ maxLength: 128 }}
                                        value={hidDetails?.id || ''}
                                        id='txt_cd_id'
                                        disabled
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        label={t('name') + '*'}
                                        inputProps={{ maxLength: 128 }}
                                        value={name}
                                        onChange={handleNameChange}
                                        id='txt_cd_name'
                                    />
                                </div>
                            </div>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        label={t('ip') + '*'}
                                        value={ipAddress}
                                        onChange={handleIpAddressChange}
                                        inputProps={{ maxLength: 128 }}
                                        id='txt_cd_idAddress'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id='status-select-label'>{t('status') + '*'}</InputLabel>
                                        <Select
                                            labelId='status-select-label'
                                            id='slt_cd_status'
                                            value={status}
                                            onChange={handleStatusChange}
                                            label={t('status') + '*'}
                                            inputProps={{ maxLength: 128 }}
                                        >
                                            <MenuItem value='1'>{t('active').toUpperCase()}</MenuItem>
                                            <MenuItem value='2'>{t('vacations').toUpperCase()}</MenuItem>
                                            <MenuItem value='3'>{t('locked').toUpperCase()}</MenuItem>
                                            <MenuItem value='4'>{t('inactive').toUpperCase()}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        label={t('channel_id') + '*'}
                                        value={channelId}
                                        onChange={handleChannelIdChange}
                                        inputProps={{ maxLength: 128 }}
                                        id='txt_cd_channelId'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <TextField
                                        size='small'
                                        className='inputField'
                                        fullWidth
                                        label={t('SCP_ID') + '*'}
                                        value={scpId}
                                        onChange={handleScpIdChange}
                                        inputProps={{ maxLength: 128 }}
                                        id='txt_cd_scpId'
                                    />
                                </div>
                            </div>
                            <div className='fourInputs'>
                                <div className='col-md-6'>
                                    <div className='checkbox-common col-md-6'></div>
                                </div>
                            </div>

                            <div className='col-md-12'>
                                <TextField
                                    size='small'
                                    className='inputField'
                                    fullWidth
                                    id='txt_cd_description'
                                    label={t('description')}
                                    inputProps={{ maxLength: 512 }}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    multiline
                                    rows={4}
                                    style={{ color: '#707070' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='panelTables px-1 animated-div'>
                    <div className='mt-5 row order_data_component'>
                        <div className='formCard'>
                            <div className='col-md-11'>
                                <Grid display='flex' gap='0.8rem' alignItems='baseline'>
                                    <p className='__header'>{t('format')}</p>
                                    <div className='span' style={spanStyles}></div>
                                </Grid>
                                {getHidRecordDetails?.formats?.length > 0 && (
                                    <div className='container-top-right-btns'>
                                        <button
                                            id='btn_cd_deleteFormat'
                                            onClick={() => {
                                                setDeleteFormatShow(true);
                                            }}
                                            className='delete-btn-2'
                                        >
                                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                                            {t('delete')}
                                        </button>
                                    </div>
                                )}
                                <div className='__body2'>
                                    {getHidRecordDetails?.formats?.length > 0 ? (
                                        <table className='w-100'>
                                            <thead className='no-border-thead'>
                                                <tr>
                                                    <th className='first_head'>
                                                        <Tooltip
                                                            title={t('de_/_select_all').toUpperCase()}
                                                            placement='top'
                                                        >
                                                            <Checkbox
                                                                className='grid-checkall checkbox'
                                                                checked={isAllCheckedFormat}
                                                                onChange={handelDeleteAllFormat}
                                                                size='small'
                                                            />
                                                        </Tooltip>
                                                    </th>
                                                    <th className='first_head'>{t('name')}</th>
                                                    <th>{t('facility_code')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getHidRecordDetails?.formats?.map((item) => (
                                                    <tr key={item?.id}>
                                                        <td
                                                            className='first'
                                                            style={{ verticalAlign: 'middle', width: '50px' }}
                                                        >
                                                            <Checkbox
                                                                className='grid-checkall checkbox'
                                                                checked={selectFormatForDelete?.includes(item?.id)}
                                                                id={item?.id}
                                                                onChange={handleCheckboxChangeFormat}
                                                                size='small'
                                                            />
                                                        </td>
                                                        <td className='first'>{item?.formatName}</td>
                                                        <td>{item?.facilityCode}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <NotInformation
                                            text={t('no_information')}
                                            card={true}
                                            style={{ marginTop: '20px', marginBottom: '20px' }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <AddButtonPlus
                            id='btn_cd_addFormat'
                            text={t('add_card_format')}
                            onClick={handleAddButtonClick}
                        />
                        <CreateFormats
                            controllerId={controllerId}
                            show={showCreateFormatModal}
                            onHide={() => setShowCreateFormatModal(false)}
                            addNewFormat={addNewFormat}
                            changed={changed}
                        />
                    </div>
                </div>
                <div className='panelTables px-1 animated-div'>
                    <div className='mt-5 row order_data_component'>
                        <div className='formCard'>
                            <div className='col-md-11'>
                                <Grid display='flex' gap='0.8rem' alignItems='baseline'>
                                    <p className='__header'>{t('SIOs')}</p>
                                    <div className='span' style={spanStyles}></div>
                                </Grid>
                                {getHidRecordDetails?.sios?.length > 0 && (
                                    <div className='container-top-right-btns'>
                                        <button
                                            id='btn_cd_deleteSio'
                                            onClick={() => {
                                                setDeleteSioShow(true);
                                            }}
                                            className='delete-btn-2'
                                        >
                                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                                            {t('delete')}
                                        </button>
                                    </div>
                                )}
                                <div className='__body2'>
                                    {getHidRecordDetails?.sios?.length > 0 ? (
                                        <table className='w-100'>
                                            <thead className='no-border-thead'>
                                                <tr>
                                                    <th className='first_head'>
                                                        <Tooltip
                                                            title={t('de_/_select_all').toUpperCase()}
                                                            placement='top'
                                                        >
                                                            <Checkbox
                                                                className='grid-checkall checkbox'
                                                                checked={isAllCheckedSio}
                                                                onChange={handelDeleteAllSio}
                                                                size='small'
                                                            />
                                                        </Tooltip>
                                                    </th>
                                                    <th className='first_head'>{t('N°')}</th>

                                                    <th>{t('number')}</th>

                                                    <th>{t('sio_type')}</th>

                                                    <th>{t('device_type')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getHidRecordDetails?.sios?.map((item) => (
                                                    <tr key={item?.id}>
                                                        <td
                                                            className='first'
                                                            style={{ verticalAlign: 'middle', width: '50px' }}
                                                        >
                                                            <Checkbox
                                                                className='grid-checkall checkbox'
                                                                checked={selectSioForDelete?.includes(item?.id)}
                                                                id={item?.id}
                                                                onChange={handleCheckboxChangeSio}
                                                                size='small'
                                                            />
                                                        </td>
                                                        <td className='first'>{item?.noSio}</td>
                                                        <td>{item?.number}</td>
                                                        <td>{item?.sioType}</td>
                                                        <td>{item?.deviceType}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <NotInformation text={t('no_information')} card={true} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <AddButtonPlus
                            id='btn_cd_addSio'
                            text={t('add_sio')}
                            onClick={() => setShowCreateSIOSModal(true)}
                        />
                        <CreateSIOSForController
                            controllerId={controllerId}
                            show={showCreateSIOSModal}
                            onHide={() => setShowCreateSIOSModal(false)}
                            addNewSio={addNewSio}
                            changed={changed}
                        />
                    </div>
                </div>
                <div className='panelTables px-1 animated-div'>
                    <div className='mt-5 row order_data_component'>
                        <div className='formCard'>
                            <div className='col-md-11'>
                                <Grid display='flex' gap='0.8rem' alignItems='baseline'>
                                    <p className='__header'>{t('ACRS')}</p>
                                    <div className='span' style={spanStyles}></div>
                                </Grid>
                                {getHidRecordDetails?.formats?.length > 0 && (
                                    <div className='container-top-right-btns'>
                                        <button
                                            id='btn_cd_delete'
                                            onClick={() => {
                                                setDeleteReaderShow(true);
                                            }}
                                            className='delete-btn-2'
                                        >
                                            <i className='fa fa-trash-o' aria-hidden='true'></i>
                                            {t('delete')}
                                        </button>
                                    </div>
                                )}
                                <div className='__body2'>
                                    {getHidRecordDetails?.sios?.some((sio) => sio.readers?.length > 0) ? (
                                        <table className='w-100'>
                                            <thead className='no-border-thead'>
                                                <tr>
                                                    <th className='first_head'>
                                                        <Tooltip
                                                            title={t('de_/_select_all').toUpperCase()}
                                                            placement='top'
                                                        >
                                                            <Checkbox
                                                                className='grid-checkall checkbox'
                                                                checked={isAllCheckedReader}
                                                                onChange={handelDeleteAllReader}
                                                                size='small'
                                                            />
                                                        </Tooltip>
                                                    </th>
                                                    <th className='first_head'>{t('reader_n')}</th>
                                                    <th>{t('zone')}</th>
                                                    <th>{t('access_type')}</th>
                                                    <th>{t('sio')}</th>
                                                    <th>{t('address')}</th>
                                                    <th>{t('key_pad')}</th>
                                                    <th>{t('comm_protocol')}</th>
                                                    <th>{t('relay_open(s)')}</th>
                                                    <th>{t('mode')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getHidRecordDetails?.sios?.flatMap((sio) =>
                                                    sio.readers?.map((item) => (
                                                        <tr key={item?.id}>
                                                            <td
                                                                className='first'
                                                                style={{ verticalAlign: 'middle', width: '50px' }}
                                                            >
                                                                <Checkbox
                                                                    className='grid-checkall checkbox'
                                                                    checked={selectReaderForDelete?.includes(item?.id)}
                                                                    id={item?.id}
                                                                    onChange={handleCheckboxChangeReader}
                                                                    size='small'
                                                                />
                                                            </td>
                                                            <td className='first'>{item?.readerNo}</td>
                                                            <td>{item?.zoneName || '-'}</td>

                                                            <td>
                                                                {item?.deviceAccessTypeId ? (
                                                                    <img src={arrowUP} alt='arrowUp' />
                                                                ) : (
                                                                    <img src={arrowDown} alt='arrowDown' />
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item?.sioType === 1
                                                                    ? 'PORT 1'
                                                                    : item?.sioType === 2
                                                                      ? 'PORT 2'
                                                                      : item?.sioType === 3
                                                                        ? 'INTERNAL'
                                                                        : '-'}
                                                            </td>
                                                            <td>{item?.address}</td>
                                                            <td>
                                                                {item?.hasKeyPad === true ? (
                                                                    <img src={check} alt='check' />
                                                                ) : (
                                                                    <img src={cancel} alt='cancel' />
                                                                )}
                                                            </td>

                                                            <td>
                                                                {item?.communicationProtocol === 'OSDP_V2'
                                                                    ? 'OSDP V2'
                                                                    : item?.communicationProtocol || '-'}
                                                            </td>
                                                            <td>{item?.relay}</td>
                                                            <td>{item?.readerMode}</td>
                                                        </tr>
                                                    )),
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <NotInformation text={t('no_information')} card={true} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <AddButtonPlus
                            id='btn_cd_addAcr'
                            text={t('add_acr')}
                            onClick={() => setShowCreateReaderModal(true)}
                        />

                        <CreateReaderForController
                            controllerId={controllerId}
                            show={showCreateReaderModal}
                            onHide={() => setShowCreateReaderModal(false)}
                            addNewReader={addNewReader}
                            changed={changed}
                        />
                    </div>
                    <div className='panelTables px-1 animated-div'>
                        <div className='mt-5 row order_data_component'>
                            <div className='formCard'>
                                <div className='col-md-11'>
                                    <Grid display='flex' gap='0.8rem' alignItems='baseline'>
                                        <p className='__header'>{t('inputs')}</p>
                                        <div className='span' style={spanStyles}></div>
                                    </Grid>
                                    {getHidRecordDetails?.formats?.length > 0 && (
                                        <div className='container-top-right-btns'>
                                            <button
                                                id='btn_cd_deleteAcr'
                                                className='delete-btn-2'
                                                onClick={() => {
                                                    setDeleteInputShow(true);
                                                }}
                                            >
                                                <i className='fa fa-trash-o' aria-hidden='true'></i>
                                                {t('delete')}
                                            </button>
                                        </div>
                                    )}
                                    <div className='__body2'>
                                        {getHidRecordDetails?.sios?.some((sio) => sio.inputs?.length > 0) ? (
                                            <table className='w-100'>
                                                <thead className='no-border-thead'>
                                                    <tr>
                                                        <th className='first_head'>
                                                            <Tooltip
                                                                title={t('de_/_select_all').toUpperCase()}
                                                                placement='top'
                                                            >
                                                                <Checkbox
                                                                    className='grid-checkall checkbox'
                                                                    checked={isAllCheckedInput}
                                                                    onChange={handelDeleteAllInputs}
                                                                    size='small'
                                                                />
                                                            </Tooltip>
                                                        </th>

                                                        <th className='first_head'>{t('name')}</th>
                                                        <th>{t('number')}</th>
                                                        <th>{t('device_type')}</th>
                                                        <th>{t('sio_type')}</th>
                                                        <th>{t('reader')}</th>
                                                        <th>{t('sio_type')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getHidRecordDetails?.sios?.map((sio) =>
                                                        sio.inputs?.map((input) => (
                                                            <tr key={input?.id}>
                                                                <td
                                                                    className='first'
                                                                    style={{ verticalAlign: 'middle', width: '50px' }}
                                                                >
                                                                    <Checkbox
                                                                        className='grid-checkall checkbox'
                                                                        checked={selectInputForDelete?.includes(
                                                                            input?.id,
                                                                        )}
                                                                        id={input?.id}
                                                                        onChange={handleCheckboxChangeInput}
                                                                        size='small'
                                                                    />
                                                                </td>

                                                                <td className='first'>{input?.name}</td>
                                                                <td>{input?.number}</td>
                                                                <td>{input?.deviceType}</td>
                                                                <td>{input?.sioType}</td>
                                                                <td>{input?.reader}</td>
                                                                <td>{input?.sioType}</td>
                                                            </tr>
                                                        )),
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <NotInformation text={t('no_information')} card={true} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <AddButtonPlus
                                id='btn_cd_addInput'
                                text={t('add_input')}
                                onClick={() => setShowCreateInputModal(true)}
                            />

                            <CreateInputsForController
                                controllerId={controllerId}
                                show={showCreateInputModal}
                                onHide={() => setShowCreateInputModal(false)}
                                addNewInput={addNewInput}
                                changed={changed}
                            />
                        </div>
                    </div>
                </div>
                <div className='footer' style={{ margin: '40px' }}>
                    <button id='btn_cd_cancel' onClick={handleSubmitCancel} className='cancel'>
                        {t('cancel').toUpperCase()}
                    </button>
                    <button id='btn_cd_update' onClick={handleSubmit}>
                        {t('update').toUpperCase()}
                    </button>
                </div>
            </div>
            <DeleteModal
                show={deleteInputShow}
                onHide={() => setDeleteInputShow(false)}
                onClickFn={() => deleteSelectedInput(selectInputForDelete)}
                data={selectInputForDelete}
                title_modal={t('delete_input')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('input')}
                deleteInput={deleteInput}
            />
            <DeleteModal
                show={deleteSioShow}
                onHide={() => setDeleteSioShow(false)}
                onClickFn={() => deleteSelectedSio(selectSioForDelete)}
                data={selectSioForDelete}
                title_modal={t('delete_sio')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('sio')}
                deleteSio={deleteSio}
            />
            <DeleteModal
                show={deleteFormatShow}
                onHide={() => setDeleteFormatShow(false)}
                onClickFn={() => deleteSelectedFormat(selectFormatForDelete)}
                data={selectFormatForDelete}
                title_modal={t('delete_format')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('format')}
                deleteFormat={deleteFormat}
            />
            <DeleteModal
                show={deleteReaderShow}
                onHide={() => setDeleteReaderShow(false)}
                onClickFn={() => deleteSelectedReader(selectReaderForDelete)}
                data={selectReaderForDelete}
                title_modal={t('delete_reader')}
                description={'are_you_sure_you_want_to_delete'}
                element_modal={t('reader')}
                deleteReader={deleteReader}
            />
        </>
    );
};

export default ControllerDetails;
