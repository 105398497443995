// React
import React, { useEffect, useState } from 'react';
// Imagenes de distintas terminaciones
import turqoiseLogo from './../../assets/images/iEntry_corporate.png';
import userSolid from './../../assets/images/user-solid.png';
import tokenIcon from '../../assets/images/ic-key.png';
import eyeSlashClose from './../../assets/images/eye-slash-solid.png';
import eyeSlashopen from './../../assets/images/eye-solid-open.svg';
// OTP
import OtpInput from 'react-otp-input';
// React Router
import { useNavigate } from 'react-router';
// Toastify
import { toast } from 'react-toastify';
// Material UI
import { InputAdornment, TextField, Grid, Typography, Box } from '@mui/material';
// Crypto
import cryptoJs from 'crypto-js';
// Configuration
import securekey from '../../config';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// ReduxTolkit
import { loginMiddleware, tokenApi } from '../../reduxToolkit/authentication/AuthenticatonApi';
import { GetUserByIdAtEntry } from '../../reduxToolkit/authentication/AuthenticatonApi';
import { GetContractorsByUserId } from '../../reduxToolkit/Contractor/ContractorApi';
import { GetUserFullInformation } from '../../reduxToolkit/Commons/CommonsApi';
import { GetProvidersByUserId } from '../../reduxToolkit/Providers/providersApi';
// Translation
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = new URLSearchParams();

    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [emailTouched, setEmailTouched] = useState(false); // Para saber si el usuario ha tocado el input de email
    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [passwordTouched, setPasswordTouched] = useState(false); // Para saber si el usuario ha tocado el input de password
    const [otp, setOtp] = useState('');

    const { getProvidersByUserId } = useSelector((state) => state.providersSlice);
    const { getContractorsByUserId } = useSelector((state) => state.ContractorSlice);

    useEffect(() => {
        document.getElementById('overlay').style.display = 'none';
        sessionStorage.removeItem('providerId');
        sessionStorage.removeItem('contractorId');
    }, []);

    const handleKeyDown = (e) => {
        // Tecla del Enter
        if (e.key === 'Enter') {
            e.preventDefault();
            handleLogin();
        }
    };

    const handleChangeStateOfTextFieldPsswrd = (e) => {
        const enteredPassword = e.target.value;
        if (passwordTouched && !enteredPassword) {
            setPasswordError('Please enter a password');
        } else {
            setPasswordError(null);
        }
    };

    const onBlurPassword = () => {
        setPasswordTouched(true);
    };

    const handleEmailChange = (e) => {
        const enteredEmail = e.target.value;
        if (emailTouched && !enteredEmail.trim()) {
            setEmailError('Please enter an email address');
        } else if (!emailRegex.test(enteredEmail) && emailTouched) {
            setEmailError('Please enter an valid email address');
        } else {
            setEmailError(null);
        }
    };
    const onBlurEmail = () => {
        setEmailTouched(true);
    };
    const handleLogin = () => {
        params.append('email', email);
        params.append('password', password);
        params.append('code', otp);
        if (email !== '' && password !== '' && otp !== '') {
            dispatch(tokenApi(params)).then(({ payload: { data, status } }) => {
                if (status === 200) {
                    setLoading(false);
                    const accessToken = cryptoJs.AES.encrypt(data.access_token, securekey);
                    const isSessionActive = sessionStorage.setItem('bearerToken', accessToken);
                    dispatch(
                        loginMiddleware({
                            values: {
                                email: email,
                                password: password,
                                code: otp,
                            },
                            navigate,
                        }),
                    ).then(({ payload: { data, success } }) => {
                        if (success) {
                            sessionStorage.setItem('id', data?.id);
                            const uuid = {
                                userId: data?.id,
                            };
                            dispatch(GetUserFullInformation(data?.id)).then(({ payload: { data } }) => {
                                sessionStorage.setItem('selfie', data?.data?.selfie);
                            });
                            if (data?.userType?.name === 'SUPPLIER_IN_CHARGE') {
                                dispatch(GetProvidersByUserId(uuid)).then(({ payload: { data } }) => {
                                    sessionStorage.setItem('providerId', data?.data?.id);
                                });
                            }
                            if (data?.userType?.name == 'CONTRACTOR_IN_CHARGE') {
                                dispatch(GetContractorsByUserId(uuid)).then(({ payload: { data } }) => {
                                    sessionStorage.setItem('contractorId', data?.data?.id);
                                });
                            }
                            if (data?.userType?.name == 'EMPLOYEE') {
                                dispatch(GetUserByIdAtEntry());
                            }
                        }
                    });
                }
            });
        } else {
            toast.warn('Please Fill All Fields..!');
        }
    };

    return (
        <>
            <Grid
                container
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'center'}
                spacing={0}
                className='section section--main-backgroundImage'
            >
                <Box className='section section-formLogin'>
                    <Box className='section section-allFormContent'>
                        <Box className='section section-logoImg'>
                            <img src={turqoiseLogo} alt='iEntryCorporate logo' className='img imgLogoIEntry' />
                        </Box>
                        <Box className='section section-titleLogin'>
                            <Typography variant='h1' className='h1-LoginTitle'>
                                {t('login')}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '12px',
                            }}
                        >
                            <form onSubmit={(e) => e.preventDefault()} className='form-login'>
                                <TextField
                                    required
                                    error={emailError !== null}
                                    onBlur={onBlurEmail}
                                    helperText={emailError}
                                    size='small'
                                    className='input input--email'
                                    label={t('email')}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        handleEmailChange(e);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <img
                                                    src={userSolid}
                                                    width='20px'
                                                    height='23px'
                                                    style={{
                                                        zIndex: '1',
                                                    }}
                                                    alt='userSolid'
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            color: 'red!important', // Color del helperText
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#707070!important', // Color del label
                                            border: 'black 1px!important', // quitar borde del label
                                        },
                                    }}
                                    sx={{
                                        borderRadius: '7px',
                                        marginTop: '1rem',
                                        '& .MuiInputBase-input::placeholder': {
                                            color: 'black!important',
                                            // opacity: 1,
                                        },
                                        '& .MuiInputBase-root': {
                                            color: 'black!important',
                                        },
                                        '& label.Mui-focused': {
                                            color: 'black!important',
                                        },
                                        '& label': {
                                            color: 'black!important',
                                        },
                                        '&:hover label': {
                                            color: 'black!important',
                                        },
                                    }}
                                />

                                <TextField
                                    required
                                    id='input-password'
                                    size='small'
                                    className='input input--password'
                                    label={t('password')}
                                    error={passwordError !== null}
                                    helperText={passwordError}
                                    onBlur={onBlurPassword}
                                    type={passwordShown ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        handleChangeStateOfTextFieldPsswrd(e);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {passwordShown ? (
                                                    <img
                                                        src={eyeSlashopen}
                                                        alt='keyimg'
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setPasswordShown(false)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={eyeSlashClose}
                                                        alt='keyimg'
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setPasswordShown(true)}
                                                    />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            color: 'red!important',
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#707070!important',
                                            border: 'black 1px!important',
                                        },
                                    }}
                                    sx={{
                                        borderRadius: '7px',
                                        marginTop: '1rem',
                                        '& .MuiInputBase-input::placeholder': {
                                            color: 'black!important',
                                            opacity: 1,
                                        },
                                        '& label.Mui-focused': {
                                            color: 'black!important',
                                        },
                                        '&:hover label': {
                                            color: 'black!important',
                                        },
                                        '& .MuiInputBase-input::placeholder': {
                                            color: 'black!important',
                                            // opacity: 1,
                                        },
                                        '& .MuiInputBase-root': {
                                            color: 'black!important',
                                        },
                                        '& label.Mui-focused': {
                                            color: 'black!important',
                                        },
                                        '& label': {
                                            color: 'black!important',
                                        },
                                        '&:hover label': {
                                            color: 'black!important',
                                        },
                                    }}
                                />
                                <div className='tokenWrapper'>
                                    <p>{t('token')}</p>
                                    <img src={tokenIcon} alt='keyimg' width='22px' height='22px' />
                                </div>
                                <OtpInput
                                    value={otp}
                                    containerStyle={{
                                        width: '75%',
                                        justifyContent: 'space-between',
                                        marginBottom: '20px',
                                    }}
                                    isInputNum={true}
                                    inputStyle={{
                                        width: '50px',
                                        height: '40px',
                                        fontWeight: 'bold',
                                        borderRadius: '8px',
                                        background: 'transparent',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        border: '2px solid #707070',
                                        outline: 'none',
                                        textAlign: 'center',
                                    }}
                                    onChange={(newValue) => setOtp(newValue)}
                                    onKeyDown={handleKeyDown}
                                    numInputs={6}
                                />
                                <button
                                    className='btn btn--signin'
                                    type='submit'
                                    onKeyDown={handleKeyDown}
                                    id='btnsignin'
                                    onClick={() => handleLogin()}
                                >
                                    {loading ? 'checking...' : t('sign_in')}
                                </button>
                            </form>
                            <Typography variant='h5' className='h1-LoginVersion' style={{ paddingBottom: '20px' }}>
                                {t('v demo-1.0.8.2')}
                            </Typography>
                        </Box>
                    </Box>
                    <footer className='section section-footerLogin'>
                        <Typography variant='p' className='paragraph-rightsReserved'>
                            {t('rights_reserved') + ' '} <b>{t('app_title')}</b>
                        </Typography>
                    </footer>
                </Box>
            </Grid>
        </>
    );
};

export default Home;
